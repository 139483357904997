.background-image__wrapper
    position absolute
    overflow hidden
    height 100%
    width 100%
    touch-action none
    pointer-events none
    user-drag none
    -webkit-user-drag none


#background-image
    will-change transform
    position absolute
    width 100%
    height 100%
