.tour-player.load
    #renderer
        cursor progress

.tour-player.active
    #renderer
        cursor pointer

#renderer
    cursor grab

    &:active
        cursor grabbing
