#tooltip
  position absolute
  background-color white
  padding 5px 10px
  transform translate(-100px, -100px)
  will-change transform
  pointer-events none
  margin-top 16px
  margin-left 16px
  visibility hidden



@media (hover: none)
  #tooltip
    display none
