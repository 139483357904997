$btn-size = 36px
$progress-bar-height = 3px

#control-panel
    pointer-events none
    position absolute
    width 100%
    text-align center
    z-index 1
    opacity 0
    left 0
    bottom calc(var(--safe-area-bottom, 0) + 18px)
    transition all.4s ease
    user-select none
    touch-action none

    &.visible
        opacity 1

        .panel
            pointer-events all

    .panel
        position relative
        display inline-block
        height $btn-size
        background #fff
        box-shadow $block-shadow

    &.hidden
        bottom $btn-size * 2

    .progress-bar
        position absolute
        width 100%
        height $progress-bar-height
        bottom $btn-size
        background $gray
        transition height.1s linear, opacity.1s linear
        transition-delay 0.1s

        .value
            background $blue
            height inherit
            width 0
            transition width.1s linear

        &.hidden
            height 0
            opacity 0
            transition height.3s linear, opacity.3s linear
            transition-delay 0.5s

    .btn-list
        font-size 0
        height $btn-size

        div
            display inline-block
            width $btn-size
            height $btn-size
            background center no-repeat
            cursor pointer
            transition transform.3s ease

            &:hover
                background-color rgba(0, 0, 0, .05)

            &:active
                background-color rgba(0, 0, 0, .1)

        .left
            background-image embedurl("../img/controls/left.svg", "utf8")
        .right
            background-image embedurl("../img/controls/right.svg", "utf8")
        .up
            background-image embedurl("../img/controls/up.svg", "utf8")
        .down
            background-image embedurl("../img/controls/down.svg", "utf8")
        .zoom-in
            background-image embedurl("../img/controls/zoom_in.svg", "utf8")
        .zoom-out
            background-image embedurl("../img/controls/zoom_out.svg", "utf8")
        .fullscreen
            background-image embedurl("../img/controls/fullscreen.svg", "utf8")
        .rotate
            background-image embedurl("../img/controls/rotate.svg", "utf8")

    &[data-orientation="landscape-primary"]
        .marker
            transform rotate(-90deg)
    &[data-orientation="landscape-secondary"]
        .marker
            transform rotate(90deg)
    &[data-orientation="portrait-secondary"]
        .marker
            transform rotate(180deg)

body.fullscreen
    #control-panel .fullscreen
        background-image embedurl("../img/controls/fullscreen_exit.svg", "utf8")

@supports (backdrop-filter: none)
    #control-panel
        .panel
            background rgba(white, .8)
            backdrop-filter brightness(1.5) blur(40px)

@media (prefers-color-scheme: dark)
    #control-panel
        .panel
            background rgba($dark-black, .9)

        .btn-list
            div
                filter brightness(1.8)
