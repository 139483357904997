.popup-layout
  position absolute
  overflow auto
  top 0
  right 0
  bottom 0
  bottom var(--safe-area-bottom, 0)
  left 0
  display flex
  visibility hidden
  justify-content center
  padding 32px
  z-index 2
  background rgba(#000, .6)
  opacity 0

  &.visible
    opacity 1
    transition opacity.3s ease
    visibility visible
    transform none

    .popups
      transition transform.3s ease
      transform scale(1)

  .popups
    margin auto
    position relative
    transform scale(.8)

  .close
    position absolute
    background #fff no-repeat
    background-position center
    background-image embedurl("../img/close.svg", "utf8")
    right 0
    top 0
    width 32px
    height 32px
    cursor pointer
    z-index 1

    &:hover
      background-color $gray

  .popup
    display none
    flex-direction column
    position relative
    min-width 200px
    max-width 400px
    min-height 140px
    background #fff
    box-shadow $block-shadow
    border-radius 7px
    -webkit-overflow-scrolling touch
    overflow hidden

    &:target
      display block

    img, video, .slider
      width 100%
      display block

    ul
      padding-left 2em

      li
        margin-bottom 0.2em
        line-height 1.4em

    .content
      user-select text
      box-sizing border-box
      padding 22px

    p
      margin-right 2em
      line-height 1.6em

@media (min-width: 640px)
  .popup-layout.landscape .popup,
  .popup-layout .popup.landscape
    flex-direction row
    align-items self-start
    max-width 800px

    &:target
      display flex

    img, video, .slider
      width 62%

      img
        width 100%

    .content
      min-width 150px
      max-width: 200px

      h1
        margin-top 0

      p
        margin-right 0

