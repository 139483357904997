#gallery
    position absolute
    width 200px
    left -200px
    height 100%
    height var(--app-height, 100%)
    background rgba(0, 0, 0, .8)
    z-index 2
    transition left.3s ease
    will-change left

    &.visible
        left 0

    .gallery-toggler
        cursor pointer
        position absolute
        background rgba(0, 0, 0, .8)
        top 0
        bottom 0
        margin auto 0
        right -18px
        width 18px
        height 50px
        border-radius 0 5px 5px 0

        &:after
            content ''
            display block
            position absolute
            top 0
            right 0
            bottom 0
            left 0
            background-image embedurl("../img/gallery-toogler.svg", "utf8")
            background-position center
            background-repeat no-repeat
            transform scale(-1, 1)

    &.visible
        .gallery-toggler:after
            transform scale(1, 1)

    ul
        margin 0
        padding 5px
        height 100%
        overflow hidden
        overflow-y scroll
        box-sizing border-box
        transform translateZ(0px)
        will-change scroll-position
        -webkit-overflow-scrolling touch

        @supports (-webkit-overflow-scrolling: touch)
            &::-webkit-scrollbar
                width 8px
                background rgba(black, .5)

            &::-webkit-scrollbar-thumb
                background rgba(white, .8)
                width 4px
                margin 3px
                border-radius 4px
                transform scale(0.1)

                &:hover
                    background white

        li
            box-sizing border-box
            background-size 100%
            background-position center
            position relative
            cursor pointer
            background-color #666
            padding 0
            margin 5px 0
            box-shadow 0 0 0 0 white
            transition box-shadow.1s ease


            &.active
                box-shadow 0 0 0 5px $blue

            &:hover
                box-shadow 0 0 0 2px white

            &:before
                content ''
                height 0
                display block
                padding-top 50%

            .item-title
                position absolute
                bottom 0
                margin 0
                background linear-gradient(to top, rgba(0, 0, 0, .5) 40%, transparent)
                color white
                padding 24px 10px 8px
                white-space nowrap
                overflow hidden
                text-overflow ellipsis
                width 100%
                box-sizing border-box

