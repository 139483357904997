*
    outline none
    -webkit-font-smoothing antialiased
    -moz-osx-font-smoothing grayscale

body, html
    height 100%
    overflow hidden
    -webkit-text-size-adjust none
    touch-action manipulation

body
    position fixed
    width 100%
    margin 0
    font $font-size 'HelveticaNeue', 'Helvetica Neue', Helvetica, Arial, sans-serif
    -webkit-tap-highlight-color transparent
    user-select none           /* Non-prefixed version */

#renderer
    position absolute
    top 0
    left 0
    width 100%
    height 100%
    user-select none
    touch-action none
    user-drag none
    -webkit-user-drag none
