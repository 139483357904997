a
    color $linkColor
    text-decoration underline
    text-decoration-color rgba($linkColor, .3)
    text-underline-position under
    transition color.3s ease, text-decoration-color.3s ease

    &:visited,
        color $visitedLinkColor
        text-decoration-color rgba($visitedLinkColor, .3)

    &:active,
    &:hover
        color $activeLinkColor
        text-decoration-color rgba($activeLinkColor, .3)
        transition color.1s ease, text-decoration-color.3s ease

    &[download],
    &[href=""],
    &[href^="#"],
    &[href^="mailto:"],
    &[href^="callto:"]
        text-decoration-style dashed
