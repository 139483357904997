#renderer
  will-change opacity

#background-image
  will-change transform

#markers
  will-change opacity

.tour-player.transition-zoomin-start
    #renderer
        opacity 0

    #markers
        opacity 0

.tour-player.transition-zoomin-end
    #markers
        opacity 1
        transition opacity .6s ease

    #renderer
        transition opacity .4s ease
        transition-delay .05s

    #background-image
        transition transform .4s ease
        transform scale(1.3)


.tour-player.transition-fadein-start
    #renderer
        opacity 0

.tour-player.transition-fadein-end
    #markers
        opacity 1
    #renderer
        transition opacity .5s ease

    #background-image
        transition transform .5s ease

