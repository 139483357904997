.dev-cursor
    position absolute
    z-index 1
    left 0
    top 0
    bottom 0
    right 0
    margin auto
    width 32px
    height 32px
    border 2px solid black
    border-radius 50%
    box-shadow 0 0 0 2px #fff
    pointer-events none
    display none
    
    &.show
        display block
